.thumbnail {
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}

.currentChart:hover {
  background-color: white !important;
}

.disabled {
  pointer-events: none;
  filter: grayscale(1);
}

.rowHeight {
  height: 320px;
}

.rowHeightSelf {
  height: 360px;
}

.card {
  position: relative;
}

.card::after {
  content: "共有";
  position: absolute;
  top: 2px;
  left: 6px;
  background-color: var(--primary);
  color: white;
  padding: 4px;
  border-radius: 10%;
}

.cardTitle {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-height: 1em;
  font-size: 1.5rem;
  font-weight: 500;
}

.cardSubTitle {
  margin-top: 0.25rem;
  display: flex;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
  color: #858585;
}

.author {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.published {
  white-space: nowrap;
}
.published::before {
  content: " · ";
}

.cardFooter {
  color: #a7a7a7;
  align-items: center;
  justify-content: space-between;
}
.cardFooterLeft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.cardFooterRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.btnLabel {
  padding: 2px 4px;
  border: 1px solid;
  border-radius: 4px;
}
