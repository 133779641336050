.dataset-source {
  padding-left: 1.25rem;
  padding-bottom: 1rem;
}

.thumbnail {
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}
