.loading-option {
  height: 48px;
  margin: 0 0 8px;
  border-radius: 4px;
  border: 1px solid transparent;
  white-space: nowrap;

  &:hover {
    background-color: var(--primary-lighter);
  }

  &:hover .dropdown {
    background-color: var(--primary-lighter);
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.active {
    background-color: var(--primary-lighter);
    border-color: var(--primary);
    opacity: 1;
  }

  &:last-child {
    // align-self: flex-end;
    // &:hover {
    //   background-color: var(--warning-lighter);
    // }
    // &:active,
    // &.active {
    //   border-color: var(--warning);
    //   background-color: var(--warning-lighter);
    // }
  }

  // &:last-child, &.separated {
  //   position: relative;
  //   &::before {
  //     content:' ';
  //     border-top: 1px solid var(--gray-400);
  //     width: 100%;
  //     position: absolute;
  //     top: -8px;
  //   }
  // }
}

.start-over {
  height: 48px;
  text-align: center;
  background-color: var(--gray-400);
  mix-blend-mode: normal;
  border: 1px solid var(--gray-400);
  box-sizing: border-box;
  border-radius: 4px;

  &.cancel {
    background: none;
    // text-decoration: underline;
    border: none;
    transition: background-color 0.35s ease;

    h4 {
      border-bottom: 1px solid var(--gray-700);
    }

    &:hover {
      background-color: var(--gray-400);
    }
  }
}

.loading-component {
  // border: 1px solid var(--primary);
  border-radius: 4px;
  color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;

  & .bg-animated {
    width: 200%;
    height: 100%;
    background: linear-gradient(-45deg, var(--primary-lighter), var(--gray-bg), var(--primary-lighter), var(--gray-bg), var(--primary-lighter));
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    animation-name: bg-animation;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  & > p {
    color: black;
    margin: 0;
  }
}

@keyframes bg-animation {
  from {
    left: 0%
  }
  to {
    left: -100%
  }
}

.activeDropdown {
  background-color: var(--primary-lighter);
  opacity: 1;
  border-bottom: none;
  box-shadow: none !important;
  padding-left: 0;

  &:hover {
    background-color: var(--primary-lighter);
  }
}

.dropdown {
  background-color: var(--gray-bg);
  opacity: 1;
  border-bottom: none;
  box-shadow: none !important;
  padding-left: 0;

  &:hover {
    background-color: var(--primary-lighter);
  }
}

.optionItem {
  width: 200px;
}

.optionItemActive {
  background-color: var(--primary-lighter);
}
