.importer {
  //border-bottom: 1px solid var(--light);
  height: 100%;
  color: rgb(24, 24, 24);
  font-size: 12px;
  margin: 0;
  padding: 0;
}

.importerChart {
  margin: 0;
  padding: 0 15px;
}

.importerFooter {
  display: flex;
  justify-content: space-between;
  color: rgb(136, 136, 136);
  font-weight: normal;
  font-size: 11px;
  text-decoration: none;
  font-style: normal;
  -webkit-box-align: center;
  align-items: center;
  margin-top: 20px;
}

.importerFooterBlock {
  display: inline;
}

.importerFooterSeparator {
  display: inline-block;
  font-style: initial;
}

.importerFooterSeparator::before {
  content: "•";
}

.importerFooterLink {
  color: rgb(0, 136, 204);
  font-weight: normal;
  text-decoration: none;
  font-style: normal;
}

.loading {
  display: flex;
  align-items: center;
}
