.chartModalDialog {
  max-width: none!important;
  width: 90%;
  text-align: center;
}

.chartModalBody {
  white-space: nowrap;
  overflow: auto;
  text-overflow: ellipsis;
  width: 100%;
}
