.chartModalDialog {
  max-width: none !important;
  width: 90%;
  text-align: left;
}

.chartModalBody {
  white-space: nowrap;
  overflow: auto;
  text-overflow: ellipsis;
  width: 100%;
}

.thumbnail {
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}

.chartModalCol {
  height: 800px;
}

.chartModalCard {
  background-color: #f9f9f9 !important;
}

.chartModalCard:hover {
  background-color: #f9f9f9 !important;
}

.chartModalCardTitle {
  color: #333333;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
}

.block:not(:last-child) {
  margin-bottom: 1.5rem;
}

.kicker {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 0;
  color: var(--gray-500);
}

.chartId {
  font-family: "Roboto Mono", Menlo, Consolas, Monaco, "Liberation Mono", monospace;
  font-weight: 700;
  font-size: 1.8rem;
}

.columns:not(.is-desktop) {
  display: flex;
}

.columns:not(:last-child) {
  margin-bottom: calc(1.5rem - 0.75rem);
}

.columns {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  margin-top: -0.75rem;
}

.column {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.75rem;
}


